import Vue from 'vue'

const menu = "dinningHall/mealtype/";

export default {

    pagination(dtr, token) { return Vue.prototype.$http.post(menu + "pagination", dtr); },
    list() { return Vue.prototype.$http.post(menu + "list"); },
    save(obj, usr) {
        return Vue.prototype.$http.post(menu + "save", obj, { params: { usr: usr } });
    }
}