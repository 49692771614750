import Service from "../Service";


const menu="dinningHall/mealconsumption/";

export default {
    massiveSave(list){console.log(list);   return    Service.post(menu+"savemassive",list);},
    pagination(dtr,token){return Service.post(menu+"pagination",dtr);},
    save(obj,usr){
        return Service.post(menu+"reservesminus",obj,{    params:{usrID:usr}});
    },
} 