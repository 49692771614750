<template>
  <v-row>
    <v-col lg="4">
      <v-dialog v-model="dialog" v-if="dialog" persistent max-width="350">
        <v-card>
          <s-toolbar label="Consumo Masivo"></s-toolbar>
          <v-card-text style="padding-bottom:0px;">
            <v-row justify="center">
              <v-col>
                <v-btn block="" color="success" small @click="save(5)"
                  >Consumo Directo</v-btn
                ></v-col
              >
              <v-col>
                <v-btn block="" color="success" small @click="save(3)"
                  >Regularización</v-btn
                ></v-col
              >
            </v-row>

            <v-divider></v-divider>

            <v-col>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn small="" @click="dialog = false" text>Cancelar</v-btn>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog>
      <s-toolbar label="Reservas - Consumos Masivos" ></s-toolbar>
      <v-card >
        
        <v-col class="s-col-form">
          <v-row justify="center">
            <v-radio-group v-model="radiomenu" row hide-details="">
              <v-radio label="Consumo Directo" value="3"></v-radio>
              <v-radio
                label="Reserva Masiva"
                value="4"
              ></v-radio> </v-radio-group
          ></v-row>
          <v-row
            ><v-col class="s-col-form">
              <s-import-excel
                @changeData="changeFile($event)"
              ></s-import-excel></v-col
          ></v-row>
          <v-row>
            <v-col class="s-col-form">
              <s-date
                v-model="filter.McsReservationDate"
                label="Fecha"
              ></s-date>
            </v-col>
            <v-col class="s-col-form">
              <s-select
                label="Seleccione Plato"
                v-model="filter.MltID"
                :items="itemsMeals"
                item-value="MltID"
                item-text="TypeMealText"
                dense
                outlined
                hide-details
              >
              </s-select> </v-col
          ></v-row>
          <v-row
            ><v-col class="s-col-form"
              ><s-text
                label="Observación"
                v-model="observation"
              ></s-text> </v-col
          ></v-row>
          <v-row v-if=IsLevelAdmin
            ><v-col class="s-col-form"
              ><s-switch
                label="Sin Descuento"
                v-model="McsWithoutDiscount"
              ></s-switch> </v-col
          ></v-row>
          <v-row
            ><v-col >
              <v-btn small="" block color="primary" @click="save(4)"
                >Guardar</v-btn
              ></v-col
            ></v-row
          >
        </v-col>
      </v-card>
    </v-col>
    <v-col lg="8">
      <s-crud :config="config" :filter="filter" remove :title="title"> </s-crud>
    </v-col>
  </v-row>
</template>
<script>
import MealTypeService from "./../../services/DinningHall/MealTypeService";
import MealConsumptionMassiveService from "./../../services/DinningHall/MealConsumptionMassiveService";
export default {
  name: "regularization",
  props: {},
  data() {
    return {
      observation: "",
      dialog: false,
      McsWithoutDiscount: false,
      McsIsFree: false,
      title: "Consumos Masivos",
      date: null,
      MltID: 0,
      itemsMeals: [],
      reserves: [],
      documents: [],
      radiomenu: "3",
      config: {
        model: {
          MenuTypeText: "string",
          MealTypeText: "string",
          MntID: "int",
          McsPersonName: "string",
          McsID: "ID",
        },
        service: MealConsumptionMassiveService,
        headers: [
          { text: "ID", value: "McsID", sortable: false },
          { text: "Trabajador", value: "McsPersonName", sortable: false },
          {
            text: "Documento",
            value: "McsPersonDocumentNumber",
            sortable: false,
          },
          { text: "Menú", value: "MenuTypeText", sortable: false },
        ],
      },
      filter: {
        McsReservationDate: new Date().toISOString().substr(0, 10),
        TypeAccessReserve: 3,
        MltID: 0,
        AreID: 0,
      },
    };
  },
  watch: {
    radiomenu(n, o) {
      this.title = "Consumos Directos";
      if (n != "3") this.title = "Reserva Masiva";
      this.filter.TypeAccessReserve = parseInt(this.radiomenu);
    },
  },
  methods: {
    save(type) {
      if (this.filter.MltID == 0 || this.filter.MltID == null) {
        this.$fun.alert("Elija Tipo de Plato", "warning");
        return;
      }
      if (this.observation.trim().length < 1) {
        this.$fun.alert("Ingrese Observación", "warning");
        return;
      }
      if (this.documents.length < 1) {
        this.$fun.alert("Archivo Vacío", "warning");
        return;
      }
      let McsStatus = 0;
      if (this.radiomenu == "3" && this.dialog == false) {
        this.dialog = true;
        return;
      }
    
     
      this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
        if (val.value) {
          this.reserves = [];
          let rs = [];
          this.documents.forEach((element) => {
            console.log('datos del excel',element)
            rs.push({
              McsPersonDocumentNumber: element.DNI.toString(),
              MltID: this.filter.MltID,
              TypeAccessReserve: type,
              McsReservationDate: this.filter.McsReservationDate,
              UsrCreateID: this.$fun.getUserID(),
              McsObservation: this.observation,
              McsStatus: 1,TypeConsumption:0,
              McsWithoutDiscount: this.McsWithoutDiscount?1:0,
              McsIsFree: this.McsIsFree?1:0,
            });
          });
          console.log('rsrsrsrsrsrsrs ', rs);
          MealConsumptionMassiveService.massiveSave(rs).then((r) => {
            if(r.status == 200) {

              this.$fun.alert("Agregados Correctamente", "success");
            } else { 
              this.$fun.alert("Ocurrio un error a realizar la carga", "error");

            }
          });
        }
      });
    },
    changeFile(data) {
      console.log('documento', data)
      this.documents = data;
    },
  },
  computed:{
    IsLevelAdmin(){return this.$fun.getSecurity().IsLevelAdmin}
  },
  created() {
    MealTypeService.list().then((result) => {
      this.itemsMeals = result.data;
    });
    this.filter.McsReservationDate = this.$moment().format("YYYY-MM-DD");
  },
};
</script>
